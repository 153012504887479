import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from 'component-library';
import moment from 'moment';
import { handleOrderStatusColor, handleOrderStatusText, handleOrderTypeText } from '../components/Orders/OrderDetails';
import { Oem, OrderList } from '../models/ApiModels';

type OrdersList = {
  oemTenantId: string;
  orderNumber: string;
  externalDocumentNumber?: string;
  orderDate: Date;
  type: string;
  status: string;
};

export function GetOrdersListData(orders: OrderList[], oems: Oem[]) {
  const orderList: any[] = orders.map((order: OrderList) => {
    const findOem = oems.find((oem: Oem) => oem.id === order.oemTenantId)?.name;
    return {
      orderId: order.orderId,
      orderNumber: order.orderNumber,
      oemTenantId: findOem,
      orderDate: order.orderDate,
      type: order.type,
      status: order.status,
      externalDocumentNumber: order.externalDocumentNumber,
    };
  });

  return orderList;
}

const columnHelper = createColumnHelper<OrdersList>();

export const ordersListColumns = [
  columnHelper.accessor('oemTenantId', {
    header: 'oem',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('orderNumber', {
    header: 'order number',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('externalDocumentNumber', {
    header: 'external reference',
    cell: (info) => <div className='whitespace-normal sm:truncate'>{info.getValue()}</div>,
    size: 300,
  }),
  columnHelper.accessor('orderDate', {
    header: 'order date',
    cell: (info) => <div className='truncate'>{moment(info.getValue()).format('YYYY-MM-DD')}</div>,
  }),
  columnHelper.accessor('type', {
    header: 'type',
    cell: (info) => (
      <div className='truncate'>
        <StatusPill fixedSize color={'Light Gray'} text={handleOrderTypeText(info.getValue())} />
      </div>
    ),
  }),
  columnHelper.accessor('status', {
    header: 'status',
    cell: (info) => (
      <div className='truncate'>
        <StatusPill
          fixedSize
          color={handleOrderStatusColor(info.getValue())}
          text={handleOrderStatusText(info.getValue())}
        />
      </div>
    ),
  }),
];
