import ServiceHelper from '../helpers/ServiceHelper';
import { OrderDetails, OrderList } from '../models/ApiModels';

export async function GetOrders(isOem: boolean) {
  return new Promise<OrderList[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/orders/${'?isOemSpecific=' + isOem}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetOrderDetails(id: string) {
  return new Promise<OrderDetails>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/orders/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function UpdateOrderDetails(id: string) {
  return new Promise<OrderDetails>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/orders/${id}/invoiced`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
