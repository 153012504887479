import { InvertButton, PrimaryButton } from 'component-library';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SoftwarePrice, System } from '../../../../models/ApiModels';
import { GetSoftwareCustomerPrices, GetSoftwareRetailPrices } from '../../../../services/BusinessCentralService';
import { UpgradeLicense } from '../../../../services/SystemService';
import FunctionPackages from './FunctionPackages';
import SystemOrderDetails from './SystemOrderDetails';
import SystemOrderOverview from './SystemOrderOverview';
import SystemOrderTermsAndConditions from './SystemOrderTermsAndConditions';

type Props = {
  system: System;
  editMode?: boolean;
  setShowUpgradeLicense: (value: boolean, didUpgrade: boolean) => void;
  topRef: any;
};

function SystemDetailsUpgradeLicense(props: Props) {
  const { id } = useParams();
  const [newPackage, setNewPackage] = useState<string>('');
  const [externalOrderReference, setExternalOrderReference] = useState<string>(
    `S/N: ${props.system?.controlUnitSerialNumber}`
  );
  const [additionalFeatures, setAdditionalFeatures] = useState<string[]>([]);
  const [prices, setPrices] = useState<SoftwarePrice>();
  const [listPrices, setListPrices] = useState<SoftwarePrice>();
  const [loading, setLoading] = useState(false);
  const [sendingOrder, setSendingOrder] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetSoftwareCustomerPrices(props.system.oemId)
      .then((response: any) => {
        setPrices(response);
        GetSoftwareRetailPrices(props.system.oemId)
          .then((response: any) => {
            setListPrices(response);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const scrollToTop = () => {
    props.topRef?.current?.scrollIntoView({ behavior: 'auto' });
  };

  async function SendOrder() {
    setSendingOrder(true);
    if (id) {
      try {
        await UpgradeLicense(id, props.system.oemId, newPackage, externalOrderReference, additionalFeatures);
        props.setShowUpgradeLicense(false, true);
      } catch {
        console.log('Something went wrong!');
      } finally {
        setSendingOrder(false);
      }
    }
  }

  return (
    <div className='flex flex-col mt-10'>
      {!loading ? (
        <>
          <div>
            <FunctionPackages
              system={props.system}
              prices={prices}
              listPrices={listPrices}
              setNewPackage={(id: string) => setNewPackage(id)}
              newPackage={newPackage}
              additionalFeatures={additionalFeatures}
              setAdditionalFeatures={(value: string) => {
                if (additionalFeatures.includes(value)) {
                  setAdditionalFeatures(additionalFeatures.filter((feat: string) => feat !== value));
                } else {
                  additionalFeatures.push(value);
                  setAdditionalFeatures([...additionalFeatures]);
                }
              }}
            />
            <SystemOrderDetails
              orderReference={externalOrderReference}
              setOrderReference={(value: any) => setExternalOrderReference(value)}
              oemId={props.system.oemId}
            />
            <SystemOrderOverview
              setNewPackage={() => setNewPackage('')}
              prices={prices}
              listPrices={listPrices}
              newPackage={newPackage}
              additionalFeatures={additionalFeatures}
              setAdditionalFeatures={(value: string) => {
                if (additionalFeatures.includes(value)) {
                  setAdditionalFeatures(additionalFeatures.filter((feat: string) => feat !== value));
                } else {
                  additionalFeatures.push(value);
                  setAdditionalFeatures([...additionalFeatures]);
                }
              }}
            />
          </div>
          <div className='flex justify-end items-center pb-5 border-b border-gray-10 gap-4'>
            <span className='prose-paragraphBase'>
              By clicking <span className='font-bold'>Send order</span> you accept Humphree portal´s{' '}
              <a onClick={() => setShowTermsAndConditions(true)} className='text-primary-100 underline cursor-pointer'>
                payment terms
              </a>
            </span>
          </div>
          <div className='flex gap-2 justify-end my-4 ml-auto p-2 w-max bg-white border border-gray-20'>
            <InvertButton
              label={'Cancel'}
              onClick={() => {
                props.setShowUpgradeLicense(false, false);
                scrollToTop();
              }}
            />
            <PrimaryButton
              label={'Send order'}
              disabled={(newPackage === '' && additionalFeatures.length === 0) || sendingOrder}
              onClick={() => SendOrder()}
            />
          </div>
        </>
      ) : (
        <div className='flex items-center flex-col gap-4 '>
          <span className='text-primary-400 prose-heading4'>Loading prices...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      )}
      {showTermsAndConditions && <SystemOrderTermsAndConditions close={() => setShowTermsAndConditions(false)} />}
    </div>
  );
}

export default SystemDetailsUpgradeLicense;
