import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Input } from 'component-library';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { GetOrdersListData, ordersListColumns } from '../../helpers/OrdersListSetup';
import { OrderList } from '../../models/ApiModels';
import { GetOrders } from '../../services/OrdersService';
import useRequest from '../../utils/net/useRequest';
import useWindowSize from '../../utils/UseWindowSize';
import Table from '../Table/Table';

const Orders = () => {
  const size = useWindowSize();
  const { isHumphreeUser, currentOem, currentOemTenantId } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const [orders = [], isOrdersLoading, hasError] = useRequest(() => GetOrders(isOemSpecific()), []);

  function isOemSpecific() {
    if (currentOemTenantId === '12345678-1234-1234-1234-123456789000') {
      return false;
    }
    return true;
  }

  function goToOrderDetails(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open('orders/' + orders.find((order: OrderList) => order.orderId === value.orderId).id, '_blank');
    } else navigate(value.orderId);
  }

  return (
    <div className='max-w-[2000px]'>
      <span className='prose-heading3 mb-14'>Orders</span>
      {isOrdersLoading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading orders...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <div>
          <div className='mt-14 border border-gray-10 p-4 flex justify-between items-center'>
            <div className='w-1/3 mb-1'>
              <Input
                placeholder='Search for orders...'
                icon={faSearch}
                iconbefore
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('searchValue', value);
                  setSearchValue(value);
                  searchParams.set('page', '1');
                  setSearchParams(searchParams);
                }}
              />
            </div>
          </div>
          {!hasError ? (
            <Table
              data={GetOrdersListData(orders, oems)}
              columns={ordersListColumns}
              onClick={(value: any, event?: any) => goToOrderDetails(value, event)}
              searchValue={searchValue}
              type='orders'
              setColumnVisibility={{
                oemTenantId: isHumphreeUser || currentOem?.parentOEMTenantId === null, //show for humphree users and business customers
                orderDate: size?.width > 900 || size?.width < 640,
                externalDocumentNumber: size?.width > 1200 || size?.width < 640,
                status: size?.width > 1200 || size?.width < 640,
                type: size?.width > 1400 || size?.width < 640,
              }}
            />
          ) : (
            <div className='w-full flex justify-between items-center p-4 px-8 bg-error-100 border-x border-gray-10'>
              <span className='text-white prose-paragraphBase italic'>Could not load models!</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
