import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../contexts/AppContext';
import { OrderDetails, OrderLines } from '../../models/ApiModels';
import Table from '../Table/Table';
interface Props {
  order: OrderDetails;
}

const columnHelper = createColumnHelper<OrderLines>();

const orderLineColumns = [
  columnHelper.accessor('articleNumber', {
    header: 'item number',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('articleName', {
    header: 'item name',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('currency', {
    header: 'currency',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('discount', {
    header: 'discount',
    cell: (info) => <div className='truncate'>{info.getValue()}%</div>,
  }),
  columnHelper.accessor('amount', {
    header: 'amount',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
  columnHelper.accessor('price', {
    header: 'price',
    cell: (info) => <div className='truncate'>{info.getValue()}</div>,
  }),
];

const OrderDetailsOrderLines = ({ order }: Props) => {
  const oems = useSelector((state: any) => state.oems.data);
  const { isBusinessCustomer, isHumphreeUser } = useContext(AppContext);

  return (
    <>
      {order?.indirectCustomerOrderLines && order?.indirectCustomerOrderLines.length > 0 && (
        <>
          <div className='border-b border-gray-10 py-5 pt-10'>
            <span className=' prose-heading4'>Order lines</span>
          </div>
          {(isBusinessCustomer || isHumphreeUser) && (
            <p className='border-b border-gray-10 py-5'>
              Information to give to your customer, you will regardless be invoiced based on your order lines below
            </p>
          )}
          <div className=''>
            <Table
              data={order?.indirectCustomerOrderLines ? order.indirectCustomerOrderLines : []}
              columns={orderLineColumns}
              setColumnVisibility={{ discount: false, articleNumber: false, amount: false }}
            />
          </div>
        </>
      )}
      {order?.orderLines && order?.orderLines.length > 0 && (
        <>
          <div className='py-5 pt-10'>
            <span className='prose-heading4'>Business customer order lines</span>
          </div>

          <div className='border-t border-gray-10'>
            <Table
              data={order?.orderLines ? order.orderLines : []}
              columns={orderLineColumns}
              setColumnVisibility={{ price: false }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetailsOrderLines;
